// import { useState } from "react";
import formatDecimal from "../../CustomFunctions/formatDecimal";
import formatPrice from "../../CustomFunctions/formatPrice";
import formatCurrency from "../../CustomFunctions/formatCurrency";

const PhysicalProperty = (props) => {
    let { url, image, name, target_irr, target_coc, target_apr, price, sold, num_sold, total_tokens, currency, percent_sold, collection,country,tokens_left } = props.data;
    let tokensLeft=tokens_left;
    try {
        target_coc = target_coc.toFixed(2);
    }
    catch { }
    try{
        target_irr = target_irr.toFixed(2);
    }catch{}
    try{
        target_apr=target_apr.toFixed(2);
    }catch{}
    if (num_sold === undefined) {
        num_sold = 0;
    }
    if (total_tokens === undefined || total_tokens === 0 || num_sold === undefined || num_sold ===0) {
        total_tokens = 0;
        tokensLeft = "N/A";
    }
    else {
        tokensLeft = total_tokens - num_sold;
    }
    if(percent_sold>1){
        percent_sold=1
    }
    // if(country){
    //     if(Object.values(countrycode()).includes(country)){
    //         country=Object.keys(countrycode()).find(key => countrycode()[key] === country);
    //     }else if(Object.values(countrycode()).includes(country.toLowerCase())){
    //         country=Object.keys(countrycode()).find(key => countrycode()[key] === country.toLowerCase());
    //     }
    // }
    // console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
    // console.log(['tokensLeft', tokensLeft]);
    //num_sold = 50
    //total_tokens = 100;
    // console.log("=+++++++++++++++++==");
    // console.log([num_sold, total_tokens]);
    // console.log(props.data);

    return (
        <div className="col-md-6 col-lg-4">
            <div className="property-info border mb-4">
                <div className="property-image position-relative">
                    <a href={url} className="d-block h-100" target="_blank" rel="noreferrer noopener">
                        <img src={image} alt={name} className='img-fluid' target="_blank" rel="noreferrer" loading='lazy'/>
                    </a>
                  {/* {country &&  <img src={`/images/icons/flags/${country}.svg`} alt='country flag' crossOrigin="anonymous" style={{position:"absolute" , right:'8px',top:'8px',borderRadius:'4px',boxShadow:'0px 0px 1px 1px white'}} width='40' height='20' loading='lazy'/>} */}
                </div>
                <div className="property-details pt-3 position-relative" style={{ minHeight: '170px' }}>
                    <div className="property-details__item my-2 mb-3 px-4">
                        <h5 className="custom-tooltip" tooltip-data={name}><span className="single-liner">{name}</span></h5>
                    </div>
                    <div className="property-details__item d-flex mb-2 px-4 justify-content-end">
                        {target_coc && (<h5 className=" w-50 ">{target_coc ? `${formatDecimal(target_coc)}% CoC` : 'N/A CoC'}</h5>)}
                        {target_irr ? <h5 className="w-50 text-end text-primary">{target_irr ? `${target_irr}% IRR` : 'N/A IRR'}</h5> :
                            (target_apr ? <h5 className="w-50 text-end text-primary">{target_apr ? `${target_apr}% APR` : 'N/A APR'}</h5> : 'N/A APR')
                        }

                    </div>
                    <div className="property-details__item d-flex mb-0 px-4">
                        <p className="w-50">Minimum Inv.</p>
                        {price !==null && price!== undefined ?
                            <h5 className="w-50 text-end">{formatCurrency(formatPrice(price), currency)}</h5>
                            :
                            <h5 className="w-50 text-end">—</h5>
                        }
                    </div>
                    {!sold ? (
                        <div className='px-4'>
                            <div className="property-details__item mb-3">
                                {percent_sold ? (
                                    <progress className='custom-progress' value={percent_sold} max={1.0} min={0}></progress>
                                ) : (
                                    <progress className='custom-progress' value={num_sold} max={total_tokens} min={0}></progress>
                                )}
                            </div>
                            <div className="property-details__item d-flex justify-content-between flex-wrap mb-2">
                                <div className="mt-2"><p className="mb-0 land-desc">{percent_sold ? (percent_sold*100).toFixed(2) + "% Sold":`N/A`}</p></div>
                                <div className="mt-2 text-end">{`Tokens left: ${tokensLeft}`}</div>
                            </div>
                        </div>) : <div className="property-details__item text-center bg-primary text-white w-100 py-3" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', position: 'absolute', bottom: '0' }}>
                        <h4 className='m-0'>Sold Out</h4>
                    </div>}
                    <div className="property-details__item py-1 px-4 d-flex justify-content-between"><p>From {collection?.toUpperCase()}</p>{country && <p>Country {country}</p>}</div>
                </div>
            </div>
        </div >
    )
}

export default PhysicalProperty;

function countrycode(){
    return {
        "ad": "Andorra",
        "ae": "United Arab Emirates",
        "af": "Afghanistan",
        "ag": "Antigua and Barbuda",
        "ai": "Anguilla",
        "al": "Albania",
        "am": "Armenia",
        "ao": "Angola",
        "aq": "Antarctica",
        "ar": "Argentina",
        "as": "American Samoa",
        "at": "Austria",
        "au": "Australia",
        "aw": "Aruba",
        "ax": "Åland Islands",
        "az": "Azerbaijan",
        "ba": "Bosnia and Herzegovina",
        "bb": "Barbados",
        "bd": "Bangladesh",
        "be": "Belgium",
        "bf": "Burkina Faso",
        "bg": "Bulgaria",
        "bh": "Bahrain",
        "bi": "Burundi",
        "bj": "Benin",
        "bl": "Saint Barthélemy",
        "bm": "Bermuda",
        "bn": "Brunei",
        "bo": "Bolivia",
        "bq": "Caribbean Netherlands",
        "br": "Brazil",
        "bs": "Bahamas",
        "bt": "Bhutan",
        "bv": "Bouvet Island",
        "bw": "Botswana",
        "by": "Belarus",
        "bz": "Belize",
        "ca": "Canada",
        "cc": "Cocos (Keeling) Islands",
        "cd": "DR Congo",
        "cf": "Central African Republic",
        "cg": "Republic of the Congo",
        "ch": "Switzerland",
        "ci": "Côte d'Ivoire (Ivory Coast)",
        "ck": "Cook Islands",
        "cl": "Chile",
        "cm": "Cameroon",
        "cn": "China",
        "co": "Colombia",
        "cr": "Costa Rica",
        "cu": "Cuba",
        "cv": "Cape Verde",
        "cw": "Curaçao",
        "cx": "Christmas Island",
        "cy": "Cyprus",
        "cz": "Czechia",
        "de": "Germany",
        "dj": "Djibouti",
        "dk": "Denmark",
        "dm": "Dominica",
        "do": "Dominican Republic",
        "dz": "Algeria",
        "ec": "Ecuador",
        "ee": "Estonia",
        "eg": "Egypt",
        "eh": "Western Sahara",
        "er": "Eritrea",
        "es": "Spain",
        "et": "Ethiopia",
        "eu": "European Union",
        "fi": "Finland",
        "fj": "Fiji",
        "fk": "Falkland Islands",
        "fm": "Micronesia",
        "fo": "Faroe Islands",
        "fr": "France",
        "ga": "Gabon",
        "gb": "United Kingdom",
        "gb-eng": "England",
        "gb-nir": "Northern Ireland",
        "gb-sct": "Scotland",
        "gb-wls": "Wales",
        "gd": "Grenada",
        "ge": "Georgia",
        "gf": "French Guiana",
        "gg": "Guernsey",
        "gh": "Ghana",
        "gi": "Gibraltar",
        "gl": "Greenland",
        "gm": "Gambia",
        "gn": "Guinea",
        "gp": "Guadeloupe",
        "gq": "Equatorial Guinea",
        "gr": "Greece",
        "gs": "South Georgia",
        "gt": "Guatemala",
        "gu": "Guam",
        "gw": "Guinea-Bissau",
        "gy": "Guyana",
        "hk": "Hong Kong",
        "hm": "Heard Island and McDonald Islands",
        "hn": "Honduras",
        "hr": "Croatia",
        "ht": "Haiti",
        "hu": "Hungary",
        "id": "Indonesia",
        "ie": "Ireland",
        "il": "Israel",
        "im": "Isle of Man",
        "in": "India",
        "io": "British Indian Ocean Territory",
        "iq": "Iraq",
        "ir": "Iran",
        "is": "Iceland",
        "it": "Italy",
        "je": "Jersey",
        "jm": "Jamaica",
        "jo": "Jordan",
        "jp": "Japan",
        "ke": "Kenya",
        "kg": "Kyrgyzstan",
        "kh": "Cambodia",
        "ki": "Kiribati",
        "km": "Comoros",
        "kn": "Saint Kitts and Nevis",
        "kp": "North Korea",
        "kr": "South Korea",
        "kw": "Kuwait",
        "ky": "Cayman Islands",
        "kz": "Kazakhstan",
        "la": "Laos",
        "lb": "Lebanon",
        "lc": "Saint Lucia",
        "li": "Liechtenstein",
        "lk": "Sri Lanka",
        "lr": "Liberia",
        "ls": "Lesotho",
        "lt": "Lithuania",
        "lu": "Luxembourg",
        "lv": "Latvia",
        "ly": "Libya",
        "ma": "Morocco",
        "mc": "Monaco",
        "md": "Moldova",
        "me": "Montenegro",
        "mf": "Saint Martin",
        "mg": "Madagascar",
        "mh": "Marshall Islands",
        "mk": "North Macedonia",
        "ml": "Mali",
        "mm": "Myanmar",
        "mn": "Mongolia",
        "mo": "Macau",
        "mp": "Northern Mariana Islands",
        "mq": "Martinique",
        "mr": "Mauritania",
        "ms": "Montserrat",
        "mt": "Malta",
        "mu": "Mauritius",
        "mv": "Maldives",
        "mw": "Malawi",
        "mx": "Mexico",
        "my": "Malaysia",
        "mz": "Mozambique",
        "na": "Namibia",
        "nc": "New Caledonia",
        "ne": "Niger",
        "nf": "Norfolk Island",
        "ng": "Nigeria",
        "ni": "Nicaragua",
        "nl": "Netherlands",
        "no": "Norway",
        "np": "Nepal",
        "nr": "Nauru",
        "nu": "Niue",
        "nz": "New Zealand",
        "om": "Oman",
        "pa": "Panama",
        "pe": "Peru",
        "pf": "French Polynesia",
        "pg": "Papua New Guinea",
        "ph": "Philippines",
        "pk": "Pakistan",
        "pl": "Poland",
        "pm": "Saint Pierre and Miquelon",
        "pn": "Pitcairn Islands",
        "pr": "Puerto Rico",
        "ps": "Palestine",
        "pt": "Portugal",
        "pw": "Palau",
        "py": "Paraguay",
        "qa": "Qatar",
        "re": "Réunion",
        "ro": "Romania",
        "rs": "Serbia",
        "ru": "Russia",
        "rw": "Rwanda",
        "sa": "Saudi Arabia",
        "sb": "Solomon Islands",
        "sc": "Seychelles",
        "sd": "Sudan",
        "se": "Sweden",
        "sg": "Singapore",
        "sh": "Saint Helena, Ascension and Tristan da Cunha",
        "si": "Slovenia",
        "sj": "Svalbard and Jan Mayen",
        "sk": "Slovakia",
        "sl": "Sierra Leone",
        "sm": "San Marino",
        "sn": "Senegal",
        "so": "Somalia",
        "sr": "Suriname",
        "ss": "South Sudan",
        "st": "São Tomé and Príncipe",
        "sv": "El Salvador",
        "sx": "Sint Maarten",
        "sy": "Syria",
        "sz": "Eswatini (Swaziland)",
        "tc": "Turks and Caicos Islands",
        "td": "Chad",
        "tf": "French Southern and Antarctic Lands",
        "tg": "Togo",
        "th": "Thailand",
        "tj": "Tajikistan",
        "tk": "Tokelau",
        "tl": "Timor-Leste",
        "tm": "Turkmenistan",
        "tn": "Tunisia",
        "to": "Tonga",
        "tr": "Turkey",
        "tt": "Trinidad and Tobago",
        "tv": "Tuvalu",
        "tw": "Taiwan",
        "tz": "Tanzania",
        "ua": "Ukraine",
        "ug": "Uganda",
        "um": "United States Minor Outlying Islands",
        "un": "United Nations",
        "us": "United States",
        "us-ak": "Alaska",
        "us-al": "Alabama",
        "us-ar": "Arkansas",
        "us-az": "Arizona",
        "us-ca": "California",
        "us-co": "Colorado",
        "us-ct": "Connecticut",
        "us-de": "Delaware",
        "us-fl": "Florida",
        "us-ga": "Georgia",
        "us-hi": "Hawaii",
        "us-ia": "Iowa",
        "us-id": "Idaho",
        "us-il": "Illinois",
        "us-in": "Indiana",
        "us-ks": "Kansas",
        "us-ky": "Kentucky",
        "us-la": "Louisiana",
        "us-ma": "Massachusetts",
        "us-md": "Maryland",
        "us-me": "Maine",
        "us-mi": "Michigan",
        "us-mn": "Minnesota",
        "us-mo": "Missouri",
        "us-ms": "Mississippi",
        "us-mt": "Montana",
        "us-nc": "North Carolina",
        "us-nd": "North Dakota",
        "us-ne": "Nebraska",
        "us-nh": "New Hampshire",
        "us-nj": "New Jersey",
        "us-nm": "New Mexico",
        "us-nv": "Nevada",
        "us-ny": "New York",
        "us-oh": "Ohio",
        "us-ok": "Oklahoma",
        "us-or": "Oregon",
        "us-pa": "Pennsylvania",
        "us-ri": "Rhode Island",
        "us-sc": "South Carolina",
        "us-sd": "South Dakota",
        "us-tn": "Tennessee",
        "us-tx": "Texas",
        "us-ut": "Utah",
        "us-va": "Virginia",
        "us-vt": "Vermont",
        "us-wa": "Washington",
        "us-wi": "Wisconsin",
        "us-wv": "West Virginia",
        "us-wy": "Wyoming",
        "uy": "Uruguay",
        "uz": "Uzbekistan",
        "va": "Vatican City (Holy See)",
        "vc": "Saint Vincent and the Grenadines",
        "ve": "Venezuela",
        "vg": "British Virgin Islands",
        "vi": "United States Virgin Islands",
        "vn": "Vietnam",
        "vu": "Vanuatu",
        "wf": "Wallis and Futuna",
        "ws": "Samoa",
        "xk": "Kosovo",
        "ye": "Yemen",
        "yt": "Mayotte",
        "za": "South Africa",
        "zm": "Zambia",
        "zw": "Zimbabwe"
    }
}