import {useEffect, useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import mainLogo from '../images/logos/araa-logo-alpha.svg';
import {useSelector} from 'react-redux';
import searchIcon from '../images/icons/header/search-icon.svg';
import bellIcon from '../images/icons/header/bell-icon.svg';
import profileIcon from '../images/icons/header/profile-icon.svg';
import classNames from 'classnames';
import Toaster from './Toaster';
import { isLoggedIn, getUser, updateUser } from '../CustomFunctions/auth';

const Header = () => {
    const [expanded,setexpanded] = useState(false);
    const auth = isLoggedIn();//useSelector(state => state.auth.isuserloggedin);    
    
    const user = useSelector(state => state.auth.user);
    const userData = getUser();

    const [userState, setUserState ] = useState(user);
    updateUser((res)=>{
        if (JSON.stringify(res) !== JSON.stringify(userState)) {
            setUserState(res);
        }
    });
    useEffect(()=>{setUserState({...user})},[user])
    function Name(){
        try{
            let {first_name,}=JSON.parse(sessionStorage.getItem('userData'))
            return (
                <span style={{textOverflow:'ellipsis', whiteSpace: 'nowrap',overflow: 'hidden',margin:'0px 5px'}}>{first_name}</span>
            )
        }
        catch{}
    }

    // console.log(userState);
    // console.log("auth", [user]);
    const handleCollapse = () =>{
        setexpanded(!expanded);
        document.body.classList.toggle('no-scroll');
    }
    return(
        <>
        <Toaster/>
        <div className='header'>
            <div className="header__left">
                <nav className="navbar navbar-expand-lg navbar-light pb-0 ">
                    <div className="d-flex py-4 py-lg-0 flex-row-reverse flex-lg-row flex-lg-fill">
                        <Link className="navbar-brand" to={'/'}>
                            <img src={mainLogo} alt="Araa-Land" className='img-fluid' loading='lazy'/>
                        </Link>
                        {true&&(
                        <button className={`navbar-toggler ${expanded ? '' :'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded={expanded ? 'true':'false'} aria-label="Toggle navigation" onClick={handleCollapse}>
                        <span></span><span></span><span></span>
                        </button>
                        )}
                        {/* <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarNavAltMarkup"> */}
                        {true && (<div className={ classNames('collapse navbar-collapse',{'show':expanded})} id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Home
                            </NavLink>
                            <NavLink
                                to="browse"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Browse Listings
                            </NavLink>
                            <NavLink
                                to="platforms"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse} >
                                Platforms
                            </NavLink>
                            
                            <NavLink
                                to="/physical-assets"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Physical Assets
                            </NavLink>
                            <NavLink
                                to="/virtual-assets"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Virtual Assets
                            </NavLink>
                            { userState?.is_platform_user &&
                              <NavLink
                                to="form"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Partner Form
                              </NavLink>
                            }          
			    { !userState?.is_platform_user &&
			    <NavLink
                                to="dashboard"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Dashboard
                            </NavLink>}
                           
                           {/* <NavLink
                                to="community"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Community
                            </NavLink>
                            <NavLink
                                to="tools"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Tools
    </NavLink> */}
                            
                            <NavLink
                                to="propid"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                Property ID
                            </NavLink>

                {/*<NavLink
                        to="about"
                        className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                        About
</NavLink> */}
                            {/* <NavLink
                                to="test"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')}>
                                Test Page
                            </NavLink> */}
                             {/* <NavLink
                                to="about"
                                className={({ isActive }) => (isActive ? 'nav-link mt-3 mt-lg-0 me-0 me-lg-3 active' : 'nav-link mt-3 mt-lg-0 me-0 me-lg-3')} onClick={handleCollapse}>
                                About
                            </NavLink> */}
                           
                        
                        </div>
                        </div>)}
                    </div>
                </nav>
            </div>
            <div className="header__right"> {/*
                <NavLink
                    to="search"
                    className={({ isActive }) => (isActive ? 'nav-link nav-icon active' : 'nav-link nav-icon')}>
                    <img className="img-fluid" src={searchIcon} alt="search" loading='lazy'/>
                </NavLink>
                <NavLink
                    to="notifications"
                    className={({ isActive }) => (isActive ? 'nav-link nav-icon active' : 'nav-link nav-icon')}>
                <img className="img-fluid" src={bellIcon} alt="noti" loading='lazy'/>
                </NavLink>*/}
                { auth===true && auth!==undefined ? (<NavLink
                    to={userState.is_platform_user ? "partner-profile" : "profile"}
                    className={({ isActive }) => (isActive ? 'nav-link nav-icon profile-icon active' : 'nav-link nav-icon profile-icon')}>
                    <Name /><img src={profileIcon} alt="profile" loading='lazy'/>
                </NavLink>) : <NavLink 
                    to="login"
                    className={({ isActive }) => (isActive ? 'nav-link nav-icon profile-icon active' : 'nav-link nav-icon profile-icon')}>
                    <img src={profileIcon} alt="login" className='img-fluid' loading='lazy'/>
                </NavLink> }
            </div>
        </div>
        </>
    );
}

export default Header;
